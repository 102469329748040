.btn-secondary {
    border-color: #ccc;
}
.justify-text {
    text-align: justify;
}

/* ===== NavBar CSS ===== */
.navbar-collapse.collapse.show {
    background: linear-gradient(90.21deg, #343A40 -5.91%, #3e454c 111.58%);
    /* padding: 15px 25px; */
    /* display: block; */
    /* width: 210px !important; */
    position: relative;
    top: 8px;
    /* box-sizing: border-box; */
    /* z-index: -9999999999999999999999999 !important; */
}
.navbar-collapse.collapse.show .navbar-nav {
    padding: 15px 25px;
    box-sizing: border-box;
}
.navbar{
    height: 56px;
}
.nav-link{
    color: rgba(255, 255, 255, 0.7) !important;
}
.nav-link a{
    color: rgba(255, 255, 255, 0.8) !important;
}
.nav-link a:hover{
    color: rgba(255, 255, 255, 0.7) !important;
    text-decoration: none;
}
.nav-link a.active{
    display: inline-block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    padding-bottom: 0.1px;
    box-sizing: border-box;
}
/* ===== Akhir NavBar CSS ===== */


/* ===== MetaMask Config & Simulation CSS ===== */
.config-n-simulation {
    padding: 10px 0;
    /* position: relative; */
    /* background-color: black; */
}
.config-n-simulation h2 {
    /* font-size: 45px;
    font-weight: 700;
    text-align: center; */
}
.config-n-simulation p {
    /* color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%; */
}
.config-n-simulation .nav.nav-pills {
    width: 24%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}
.config-n-simulation .nav.nav-pills .nav-item {
    width: 50%;
    background-color: #555;
}
.config-n-simulation .nav.nav-pills .nav-item a {
    color: #ccc !important;
    text-shadow: 0 0 2px #000;
}
.config-n-simulation .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 10px 0;
    color: #fff;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}
.config-n-simulation .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #343A40 -5.91%, #282c30 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.config-n-simulation .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.config-n-simulation .nav.nav-pills .nav-link.active {
    /* border: 1px solid rgba(255, 255, 255, 1); */
}
.nav-link#config-n-simulation-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
}
.nav-link#config-n-simulation-tabs-tab-second {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
}
/* ===== Akhir MetaMask Config & Simulation CSS ===== */


@media only screen and (max-width: 768px) {
    .config-n-simulation .nav.nav-pills {
        width: 44%;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
